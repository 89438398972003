<template>
  <section class="cases">
    <v-img
      v-if="category && category.cat.banner !== null"
      :src="category.cat.banner"
      class="top-banner"
    >
    </v-img>
    <v-container  grid-list-md text-center id="ctk_top" style="margin-bottom:10px">
      <template>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container
        fluid
        grid-list-sm
        mb-12
      >
        <v-layout wrap v-if="cases && cases.data">
          <template v-for="(cs, i) in cases.data">
            <v-flex
              :key="i"
              xs12
              md4
              pa-2
            >
              <template>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card
                      class="mx-auto"
                    >
                      <v-img
                        :src="cs.img"
                        aspect-ratio="1"
                        max-height="470px"
                      ></v-img>
                      <v-fade-transition style="width:100%; overflow: hidden">
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="#036358"
                          class="text-left"
                          style="padding:20px;"
                        >
                          <p style="font-size: 24px; color: #FFF;word-wrap:break-word;word-break:break-all;">{{cs.title}}</p>
                          <v-btn class="ma-2" outlined style="border:none;" :href="'/cases/detail/' + cs.id"> {{detail}}> </v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card>
                  </template>
                </v-hover>
              </template>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
            style="background: #FFF !important;"
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 3,
    locale: null,
    detail: null,
    category: null,
    cases: null,
    breadcrumbs: [],
    totalPage: null,
    page: null
  }),
  created () {
    document.querySelector('#menu_case').classList.add('v-btn--active')
    this.locale = localStorage.getItem('locale')
    this.getPageData()
    this.getCategory()
    this.page = 1
  },
  watch: {
    page: {
      handler: function (val, oldVal) {
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getCases(this.$route.params.id, val)
      },
      deep: true
    },
    category: {
      handler: function (val, oldVal) {
        this.parseBreadcrumbs(val)
      },
      deep: true
    }
  },
  methods: {
    getPageData () {
      this.detail = this.$t('detail')
    },
    getCategory () {
      this.https.get('case_category', { pid: this.$route.params.pid, id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.category = response.data
          // this.parseBreadcrumbs(this.category)
        }
      })
    },
    getCases (cid, page = 1) {
      this.https.get('cases', { cid: cid, page: page }).then(response => {
        if (response.code === 0) {
          this.cases = response.data
          this.totalPage = this.cases.last_page
          this.page = page
        }
      })
    },
    parseBreadcrumbs (category) {
      this.breadcrumbs = []
      this.breadcrumbs.push({ text: this.$t('cases.name'), disabled: false, href: '/cases' })
      let cateName = 'name'
      if (this.locale !== 'cn' && this.locale !== null) {
        cateName = cateName + '_' + this.locale
      }
      if (category.pcat) {
        this.breadcrumbs.push({ text: category.pcat[cateName], disabled: false, href: '/cases/' + category.pcat.id })
      }
      if (category.cat) {
        this.breadcrumbs.push({ text: category.cat[cateName], disabled: true, href: '/cases/' + category.pcat.id + '/' + category.cat.id })
      }
    }
  }
}
</script>
